import React from 'react';

import {
  Hero,
  Layout,
  AboutCards,
  AboutUs,
  ContactFormBanner,
  BlogBanner,
  SEO,
  Carousel,
  TextWorkingWith,
  TextProjectDelivery,
} from '../components';

export default function Index(props) {
  return (
    <Layout {...props}>
      <SEO title="Home" pathname={props.location.pathname} />
      <Hero />
      <BlogBanner />
      <TextWorkingWith />
      <AboutCards />
      <TextProjectDelivery />
      <AboutUs />
      <Carousel />
      <ContactFormBanner />
    </Layout>
  );
}
